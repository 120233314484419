"use client";

import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import classNames from "classnames/bind";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { slugifyTitle } from "../../../helpers/slugifyTitle";
import {
  ExplodedWordsToSpans,
  waitForScrollConfig,
} from "../interactive-hero/utils/interactive-hero-utils";
import { SliderButton } from "../../slider-button/slider-button";
import { useEffect, createElement, useRef, useLayoutEffect } from "react";
import { useKickerContext } from "../../../helpers/KickerContext";
import { useAnalyticsTrackingState } from "../../../helpers/useAnalyticsTrackingState";
import useEmblaCarousel from "embla-carousel-react";
import { Kicker } from "../../kicker/kicker";
import { env } from "../../../helpers/env";
import styles from "./product-rail.module.css";
import { CosmosButton, CosmosIconAmazon } from "@cosmos/web/react";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { SelectButton } from "../../select-button/select-button";
import { analyticsEcommerceVendorClick } from "../../../helpers/analytics";
import { isExternalLink } from "../../../helpers/isExternalLink";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { dataLayer } from "../../../helpers/dataLayer";

type SlideItem = {
  id: string;
  backgroundColor: string | null;
  headingTextColor: string | "White" | "Black" | null;
  theme: {
    backgroundColor: string | null;
    contrastColor: string | null;
  } | null;
  canArtworkUrl: string | null;
  canArtworkDescription: string | null;
  linkUrl: string | null;
  linkText: string | null;
  name: string | null;
  brand: string | null;
};

const MINIMUM_SLIDESHOW_ITEM_COUNT = 2;
const MINIMUM_SLIDESHOW_ITEM_CLONE_COUNT = 4;
const MAXIMUM_SLIDESHOW_ITEM_CLONE_COUNT = 7;
const ANALYTICS_MAXIMUM_CARDS = 3;

const cx = classNames.bind(styles);

export const createSlideItems = <T = unknown,>(products: T[]) => {
  // Using Embla's loop option when the product count is between 4 and 7 on larger screens causes odd spacing between slide items and sporadic transitions.
  // To resolve this, when the product count is between 4 and 7, we duplicate the amount meaning the total slide items is between 8 and 14.
  if (
    products.length >= MINIMUM_SLIDESHOW_ITEM_CLONE_COUNT &&
    products.length <= MAXIMUM_SLIDESHOW_ITEM_CLONE_COUNT
  ) {
    return [...products, ...products];
  }

  if (products.length === 3) {
    /**
     * Move the the last item to the start.
     *
     * See also: note below annotated with "✝✝✝".
     */
    return [...products.slice(-1), ...products.slice(0, -1)];
  }

  return products;
};

export interface ProductRailProps {
  id: string;
  products: SlideItem[];
  intro: React.ReactNode;
  vendorLinksLabel: string | null;
  vendorLinks: {
    label: string | null;
    url: string | null;
    icon: string | null;
  }[];
  anchorScrollText: string | null;
  largeHeading: boolean;
  headingGradient: boolean;
  preHeading: string | null;
  heading: string | null;
  headingElement: "h1" | "h2";
  productsWithStamp: boolean;
  /**
   * @deprecated use vendorLinks instead
   */
  buyOnAmazonText?: string | null;
  /**
   * @deprecated use vendorLinks instead
   */
  buyOnAmazonUrl?: string | null;
  ctaButtonLabel: string | null;
  ctaButtonUrl: string | null;
  animated?: boolean;
}

export const ProductRail = ({
  id,
  products,
  intro,
  vendorLinksLabel,
  vendorLinks,
  anchorScrollText,
  largeHeading,
  headingGradient,
  preHeading,
  heading,
  headingElement,
  productsWithStamp,
  buyOnAmazonText,
  buyOnAmazonUrl,
  ctaButtonLabel,
  ctaButtonUrl,
  animated,
}: ProductRailProps) => {
  const kickerKind = useKickerContext();
  const textDirection = useTextDirectionContext();

  const slideItems = createSlideItems(products);

  const { ref, isIntersecting, hasIntersected } =
    useObserveElementRef<HTMLDivElement>({
      threshold: 0,
    });

  const analyticsTrackingState = useAnalyticsTrackingState();

  const kickerRef = useRef<HTMLSpanElement | null>(null);
  const headingRef = useRef<HTMLHeadingElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = ref.current;
      const titleWordsElements = headingRef.current?.querySelectorAll("span");
      const kickerElement = kickerRef.current;
      const descriptionElement = descriptionRef.current;
      const cardsEl = contentRef.current;
      const timeline = gsap.timeline();
      timeline.from(
        kickerElement ?? null,
        { duration: 0.4, y: "+=50", opacity: 0 },
        0,
      );
      timeline.from(titleWordsElements ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
      });
      timeline.from(
        descriptionElement ?? null,
        { duration: 0.4, y: "+=50", opacity: 0 },
        "-=0.3",
      );
      timeline.from(cardsEl ?? null, {
        duration: 0.6,
        y: "+=100",
        opacity: 0,
      });
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 80%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });
    return () => ctx.revert();
  }, [animated, ref]);

  useEffect(() => {
    if (
      env.NEXT_PUBLIC_HAS_GTM_CONTAINER &&
      isIntersecting &&
      products.length > 0
    ) {
      const activeCards = products.slice(0, ANALYTICS_MAXIMUM_CARDS);

      if (
        !analyticsTrackingState.some(
          (item) =>
            item.id === activeCards.map((card) => card.id).join(", ") &&
            item.event === "moduleImpression",
        )
      ) {
        analyticsTrackingState.push({
          id: activeCards.map((card) => card.id).join(", "),
          event: "moduleImpression",
        });

        dataLayer.push({
          event: "moduleImpression",
          availableElements: products.length,
          promotionmodule: "feed-cards-rail-panel_standard",
          ecommerce: {
            promoView: {
              promotions: activeCards.map((activeCard, index) => ({
                id: activeCard.id,
                name: activeCard.name,
                creative: "Product Rail",
                position: index + 1,
              })),
            },
          },
        });
      }
    }
  }, [isIntersecting, products, analyticsTrackingState]);

  /**
   * You might ask - isn't this redundant? Isn't this the whole point of the
   * "loading=lazy" attribute?
   *
   * The issue is carousels might be scrolled *vertically* into the viewport,
   * but images that are *horizontally* positioned offscreen will still not
   * load until they're moved into view by interacting with the carousel.
   */
  const imageLoading = hasIntersected ? "eager" : "lazy";

  const [emblaViewportRef, emblaApi] = useEmblaCarousel({
    align: "center",
    skipSnaps: true,
    direction: textDirection,
    loop: slideItems.length > MINIMUM_SLIDESHOW_ITEM_COUNT,
    draggable: true,
    active: slideItems.length !== 2,
    /**
     * ✝✝✝
     * Special-case for when there are three products: we make the
     * second one the "active" item, so that it stands out. In this
     * case we also shift the items by one in `createSlideItems` so
     * that its the first product in the original array which gets
     * the prominence.
     */
    startIndex: slideItems.length === 3 ? 1 : 0,
  });

  /**
   * Why not `embla-carousel-classnames-react`? It doesn't result in the same
   * behaviour. It basically considers every slide which is entirely within the
   * viewport to be "selected", but we only want the middle item to be
   * considered "selected".
   */
  useEffect(() => {
    if (emblaApi) {
      const syncIsSelectedClassName = () => {
        const selectedScrollSnapIndex = emblaApi?.selectedScrollSnap();
        emblaApi.slideNodes().forEach((slide, index) => {
          slide.classList.toggle(
            cx("embla-slide--is-selected"),
            selectedScrollSnapIndex === index,
          );
        });
      };

      emblaApi.on("select", () => syncIsSelectedClassName());
      syncIsSelectedClassName();
    }
  }, [emblaApi]);

  return (
    <div
      className={cx("container")}
      data-slide-length={slideItems.length}
      id={anchorScrollText ? slugifyTitle(anchorScrollText) : undefined}
      ref={ref}
    >
      <div className={cx("header")}>
        <div className={cx("header-inner")}>
          <div
            className={cx("text")}
            data-is-large={JSON.stringify(
              Boolean(largeHeading || headingGradient),
            )}
          >
            {(preHeading || heading) && (
              <div className={cx("heading-group")}>
                {preHeading && (
                  <span
                    className={cx("name-kicker-wrapper")}
                    data-has-gradient={JSON.stringify(!!headingGradient)}
                    ref={kickerRef}
                  >
                    <Kicker
                      className={cx("kicker")}
                      kind={kickerKind}
                      text={preHeading}
                    />
                  </span>
                )}
                {heading &&
                  createElement(
                    headingElement,
                    {
                      ref: headingRef,
                      className: cx("heading"),
                      "data-is-large": JSON.stringify(!!largeHeading),
                      "data-has-gradient": JSON.stringify(!!headingGradient),
                    },
                    animated ? (
                      <ExplodedWordsToSpans text={heading} />
                    ) : (
                      heading
                    ),
                  )}
              </div>
            )}
            {intro && <div className={cx("description")}>{intro}</div>}
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        className={cx("content")}
        onClick={() => {
          if (env.NEXT_PUBLIC_HAS_GTM_CONTAINER && products.length > 0) {
            if (emblaApi) {
              const cardStart = emblaApi.selectedScrollSnap();
              const cardEnd = ANALYTICS_MAXIMUM_CARDS + cardStart;

              const cards =
                cardEnd > slideItems.length
                  ? slideItems.slice(cardStart - 1)
                  : slideItems.slice(cardStart, cardEnd);

              if (
                cards.length > 0 &&
                !analyticsTrackingState?.some(
                  (item) =>
                    item.id === cards.map((card) => card.id).join(", ") &&
                    item.event === "moduleImpression",
                )
              ) {
                analyticsTrackingState.push({
                  id: cards.map((card) => card.id).join(", "),
                  event: "moduleImpression",
                });

                dataLayer.push({
                  event: "moduleImpression",
                  availableElements: products.length,
                  promotionmodule: "feed-cards-rail-panel_standard",
                  ecommerce: {
                    promoView: {
                      promotions: cards.map((activeCard, index) => ({
                        id: activeCard.id,
                        name: activeCard.name,
                        creative: "Product Rail",
                        position: index + 1,
                      })),
                    },
                  },
                });
              }
            }
          }
        }}
      >
        {slideItems.length > MINIMUM_SLIDESHOW_ITEM_COUNT && (
          <SliderButton
            kind="previous"
            className={cx("slider-button", "slider-button--prev")}
            onClick={() => emblaApi?.scrollPrev()}
          />
        )}
        {slideItems.length > MINIMUM_SLIDESHOW_ITEM_COUNT && (
          <SliderButton
            kind="next"
            className={cx("slider-button", "slider-button--next")}
            onClick={() => emblaApi?.scrollNext()}
          />
        )}
        {/* Due to 700 + sliders we keep the old `product.backgroundColor` and `product.headingTextColor` until we do a data migration */}
        <div className={cx("embla")}>
          <div className={cx("embla-viewport")} ref={emblaViewportRef}>
            <div className={cx("embla-container")}>
              {slideItems.map(
                (product, index, originalSlideItems) =>
                  product.canArtworkUrl && (
                    <div key={index} className={cx("embla-slide")}>
                      <a
                        className={cx("card")}
                        href={product.linkUrl ?? undefined}
                        style={{
                          "--card-background":
                            product.theme?.backgroundColor ??
                            product.backgroundColor ??
                            undefined,
                          "--card-color":
                            product.theme?.contrastColor ??
                            (product.headingTextColor === "White"
                              ? "var(--color-surface-solid-light-lighter)"
                              : undefined) ??
                            undefined,
                        }}
                        onClick={() => {
                          dataLayer.push({
                            event: "moduleClick",
                            availableElements: originalSlideItems.length,
                            promotionmodule: "feed-cards-rail-panel_standard",
                            promotionclicktype: "Photo",
                            ecommerce: {
                              promoClick: {
                                promotions: [
                                  {
                                    id: product.id,
                                    name: product.name,
                                    creative: "Product Rail",
                                    position: index + 1,
                                  },
                                ],
                              },
                            },
                          });
                        }}
                      >
                        <div className={cx("card-content")}>
                          <h3
                            className={cx("product-label")}
                            data-is-white={JSON.stringify(
                              product.headingTextColor === "White",
                            )}
                          >
                            {product.brand}
                            <span className={cx("product-name")}>
                              {product.linkText}
                            </span>
                          </h3>
                          {product.canArtworkUrl && (
                            <picture>
                              <source
                                media="(min-width: 767px)"
                                srcSet={[1, 1.5, 2]
                                  .map((multiplier) => {
                                    const src = getContentfulImageUrl(
                                      product.canArtworkUrl ?? "",
                                      {
                                        height: Math.ceil(453 * multiplier),
                                        fm: "webp",
                                      },
                                    );
                                    return `${src} ${multiplier}x`;
                                  })
                                  .join(", ")}
                              />
                              <source
                                srcSet={[1, 1.5, 2]
                                  .map((multiplier) => {
                                    const src = getContentfulImageUrl(
                                      product.canArtworkUrl ?? "",
                                      {
                                        height: Math.ceil(215 * multiplier),
                                        fm: "webp",
                                      },
                                    );
                                    return `${src} ${multiplier}x`;
                                  })
                                  .join(", ")}
                              />
                              <img
                                className={cx("image")}
                                data-with-stamp={JSON.stringify(
                                  !!productsWithStamp,
                                )}
                                src={product.canArtworkUrl ?? ""}
                                loading={imageLoading}
                                alt={
                                  product.canArtworkDescription ||
                                  "Packshot of the can"
                                }
                              />
                            </picture>
                          )}

                          <DietaryMarks
                            kind="high-sugar"
                            className={cx("dietary-mark-high-sugar")}
                            sugarFree={product.linkUrl?.includes(
                              "red-bull-sugarfree",
                            )}
                          />
                          <DietaryMarks
                            kind="vegetarian"
                            className={cx("dietary-mark-vegetarian")}
                          />
                        </div>
                      </a>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
      {((vendorLinks && vendorLinks.length !== 0) ||
        (buyOnAmazonText && buyOnAmazonUrl) ||
        (ctaButtonLabel && ctaButtonUrl)) && (
        <div className={cx("button-wrapper")}>
          {vendorLinks.length !== 0 && (
            <SelectButton
              id={id}
              buttonClassName={cx("button")}
              buttonSize="large"
              label={vendorLinksLabel ?? ""}
              items={
                vendorLinks.map((item) => ({
                  label: item.label ?? "",
                  url: item.url ?? "",
                  icon: item.icon ?? undefined,
                  onClick: () =>
                    analyticsEcommerceVendorClick({
                      value: item.label ?? "",
                      clickUrl: item.url ?? "",
                      elementPosition: "VendorLink",
                      method: isExternalLink(item.url ?? "")
                        ? "Outbound: TRUE"
                        : "Outbound: FALSE",
                      contentType: "Button Click, Buy now",
                    }),
                })) ?? []
              }
            />
          )}
          {ctaButtonLabel && ctaButtonUrl && (
            <CosmosButton
              href={ctaButtonUrl}
              target={isExternalLink(ctaButtonUrl) ? "_blank" : undefined}
              className={cx("cta-button")}
              size="large"
            >
              {ctaButtonLabel}
            </CosmosButton>
          )}
          {buyOnAmazonText && buyOnAmazonUrl && (
            <CosmosButton
              href={buyOnAmazonUrl}
              target={isExternalLink(buyOnAmazonUrl) ? "_blank" : undefined}
              className={cx("cta-button")}
              onClick={() =>
                analyticsEcommerceVendorClick({
                  value: buyOnAmazonText ?? "",
                  clickUrl: buyOnAmazonUrl ?? "",
                  elementPosition: "BlocksProductRail",
                  method: isExternalLink(buyOnAmazonUrl ?? "")
                    ? "Outbound: TRUE"
                    : "Outbound: FALSE",
                  contentType: "Button Click, Buy now",
                })
              }
            >
              <CosmosIconAmazon slot="icon" />
              {buyOnAmazonText}
            </CosmosButton>
          )}
        </div>
      )}
    </div>
  );
};
